import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import emailjs from '@emailjs/browser';
import styles from '../styles/Enviar.css'


export default function Login(){   

    const initialValues = {user_name:"", user_email:""};
    const [formValues, setFormvalues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [send, setSend] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {        
        const {name, value} = e.target;
        setFormvalues({...formValues, [name]:value});   
        
        setFormErrors(validate(formValues)); 
        setIsSubmit(true);
    };

    const form = useRef();

    const navigate = useNavigate()  
  

    const location = useLocation();
  
      
      useEffect(() => {
        console.log(formErrors);
        if(Object.keys(formErrors).length === 0 && isSubmit){
            setSend(true);
        }
      }, [formErrors])

      const validate = (values) => {
        const errors = {}
        const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/i;
        if(!values.user_name){
            errors.user_name = "* O nome é obrigatório!"
        }
        if(!values.user_email){
            errors.user_email = "* O email é obrigatório!"
        } else if(!regex.test(values.user_email)){
            errors.user_email = "* O email não é válido!"
        }

        return errors
      }

      const sendEmail = (e) => {      
        e.preventDefault();        
        
        if(send){       
        setLoading(true);  
        emailjs.sendForm('gmailMessage', 'template_c4jkroh', form.current, 'gw_MKWGlFtDArd1Ui')
          .then((result) => {
              console.log(result.text);                           
              alert("Email enviado com sucesso!")
              navigate('/final')
          }, (error) => {
              console.log(error.text);
          });     
        } else {
            alert("Por favor, Preencha o formulário corretamente para enviar!")
        }
        e.target.reset();         
                   
      };

    return(
        <div className='box'>       
            
            <div className='login-section'></div>
                <div className='login-text'>Obrigado por responder o questionário! <br/><br/> Por favor, insira o seu Nome e Email para enviar o resultado</div>
                <form ref={form} onSubmit={sendEmail}>
                    <label>
                        Nome
                        <input type='text' name='user_name' autoComplete='off' value={formValues.username} onChange={handleChange}/>                                        
                        <p>{formErrors.user_name}</p>
                    </label>
                    
                    <label>
                        Email
                        <input type='email' name='user_email' autoComplete='off' value={formValues.email} onChange={handleChange}/> 
                        <p>{formErrors.user_email}</p>                       
                    </label>                    
                        <input hidden name='score' value={location.state.score} readOnly/>
                        <input hidden name='resultado' value={location.state.showResultado} readOnly/>
                       {loading ? (<CircularProgress/>) : (<div></div>)}                
                    <button type='submit'>Enviar</button>                    
                </form>
        </div>
    )
}