import React from "react"
import { useNavigate } from "react-router-dom"
import styles from '../styles/Orientacoes.css'



export default function Orientacoes() {
    const navigate = useNavigate();

    function handleClick(){
        navigate('/app')
    }


    return(        
        <div className='box-orientacoes'>
            <div className='orientacoes-section'></div>
            <div className='orientacoes-texto'>
                <h2><u>Orientações Sobre as Notas:</u></h2>
                <br></br>
                <p>Você se dará uma nota de 1 a 5:</p>                
                <ul>
                    <li>Nota 1 - Não te representa em absolutamente nada</li>
                    <li>Nota 2 - Representa bem pouco você </li>
                    <li>Nota 3 - Você se comporta de maneira mediana</li>
                    <li>Nota 4 - Representa bastante você</li>
                    <li>Nota 5 - Você faz isso como um costume/hábito</li>
                </ul>
                <button onClick={handleClick}>Iniciar</button>
            </div>         
        </div>        
    )
}