import React, { useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import questions from './components/bancoQuestoes.js'
import useCollapse from 'react-collapsed';
import Home from './components/Home.js';
import Enviar from './components/Enviar.js';


export default function App(props) {	
	
	
	
	const [currentQuestion, setCurrentQuestion] = useState(0);

	const[showScore, setShowScore] = useState(false);

	const[score,setScore] = useState(0);

	const[showResultado, setShowResultado] = useState("");

	const resetStateClick =()=>{
        setCurrentQuestion (0)
        setShowScore(false)
        setScore(0)
    }

	const getData = (score) => {
		console.log("lalal")
	}

	const navigate = useNavigate()

    const handleClick = () =>{		
        navigate('/enviar', {state: 
			{score: score, showResultado: showResultado}
	})
    }



	const handleBotaoPontos = (pontos) => {
		setScore(score + pontos)
		console.log(score)

		const nextQuestion = currentQuestion + 1;
		if(nextQuestion < questions.length){
			setCurrentQuestion(nextQuestion);
		} else{
			setShowScore(true);
			mostrarResultado();
		}
	}

	function mostrarResultado() {
		if(score >=  33 && score <= 66) setShowResultado("33 À 66 – Não tem clareza do que quer realizar; falta de foco; improdutivo. Pessoa que se encontra PERDIDA, baixo resultado, baixo equilíbrio. Certa dose de ansiedade e preocupação por não saber o que fazer para mudar.")
		if(score >=  67 && score <= 99) setShowResultado("67 À 99 – Tem uma noção do que quer realizar, porém não sabe claramente como conquistar; Gasta muita energia com ações que não trazem os melhores resultados. Pessoa que se encontra ACOMODADA, baixo resultado, um pouco de equilíbrio. Sabe que pode mais, que deveria mudar mas que muitas vezes se conforma com o que já tem.")
		if(score >=  100 && score <= 132) setShowResultado("100 À 132 – Tem uma boa/melhor noção do que quer realizar, tem uma clareza maior do que precisa ser feito, porém não sabe se o que faz é exatamente o melhor caminho e as vezes não tem as ferramentas adequadas. Pessoa ESTRESSADA – tem um bom resultado mas não tem equilíbrio. Acaba se estressando muito no dia a dia pois muitas vezes não sabe tomar as decisões corretas (deveria olhar mais para dentro de si).")
		if(score >=  133 && score <= 165) setShowResultado("133 À 165 – Tem clara noção do que quer realizar, executa um bom passo a passo, porém sente que falta algo, que pode conquistar mais, que pode ter mais performance e equilíbrio (Falta as ferramentas certas). Pessoa REALIZADORA. Tem resultado e equilíbrio acima da média. Sabe da importância de utilizar o tempo a seu favor tanto para a vida pessoal quanto para a profissional.")
	}
	
	

	function Collapsible() {
		const [ isExpanded, setExpanded ] = useState(false);
		const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });
	function handleOnClick() {
			// Do more stuff with the click event!
			// Or, set isExpanded conditionally 
			setExpanded(!isExpanded);
			
		}
	return (
			<div className="collapsible">
				<div className="header" {...getToggleProps({onClick: handleOnClick})}>
					{isExpanded ? 'Fechar' : 'Clique pra ler seu resultado'}
				</div>
				<div {...getCollapseProps()}>
					<div className="content">												
							<p>{showResultado}</p>						
					</div>
				</div>
			</div>
		);
	}

	

	return (		
		<div className='app'>
			{/* HINT: replace "false" with logic to display the 
      score when the user has answered all the questions */}
			{showScore ? (
				<div className='score-section'>Sua Pontuação foi {score} 
					<Collapsible/>				
					<div><button onClick={() => {handleClick()}}>Próximo</button></div>					
				</div>
			) : (
				<>
					<div className='question-section'>
						<div className='question-count'>
							<span>Questão {currentQuestion + 1}</span><b>/{questions.length}</b>
						</div>
						<div className='question-text'>{questions[currentQuestion].questionText}</div>
					</div>
					<div className='answer-section'>
						{questions[currentQuestion].answerOptions.map((answerOptions) => 
						 <button onClick={() => handleBotaoPontos(answerOptions.pontos)}>{answerOptions.answerText}</button>)
						}
					</div>
				</>
			)}
		</div>	
	);
}
