    const questions = [
		{
			questionText: 'Em uma conversa informal você costuma se atentar a pessoa que está falando? Olha nos olhos dela? Se mostra verdadeiramente interessado? ',
			answerOptions: [
				{ answerText: '1.Não tem a ver comigo', pontos: 1 },
				{ answerText: '2.Tem bem pouco a ver comigo', pontos: 2 },
				{ answerText: '3.Faço de maneira mediana', pontos: 3 },
				{ answerText: '4.Tem um pouco a mais a ver comigo', pontos: 4 },
				{ answerText: '5.Tem muito a ver comigo', pontos: 5 },
			],
		},
		{
			questionText: 'Em relação ao seu conjuge, vocês costumam ter bons momentos juntos? Se abraçam? beijam? Demonstram amor através de pequenos gestos?',
			answerOptions: [
				{ answerText: '1.Não tem a ver comigo', pontos: 1 },
				{ answerText: '2.Tem bem pouco a ver comigo', pontos: 2 },
				{ answerText: '3.Faço de maneira mediana', pontos: 3 },
				{ answerText: '4.Tem um pouco a mais a ver comigo', pontos: 4 },
				{ answerText: '5.Tem muito a ver comigo', pontos: 5 },
			],
		},
		{
			questionText: 'Você costuma ser proativo tanto no ambiente profissional quanto no dia a dia?',
			answerOptions: [
				{ answerText: '1.Não tem a ver comigo', pontos: 1 },
				{ answerText: '2.Tem bem pouco a ver comigo', pontos: 2 },
				{ answerText: '3.Faço de maneira mediana', pontos: 3 },
				{ answerText: '4.Tem um pouco a mais a ver comigo', pontos: 4 },
				{ answerText: '5.Tem muito a ver comigo', pontos: 5 },
			],
		},
		{
			questionText: 'Você costuma ter tempo de qualidade com sua famíilia? Se relaciona de maneira carinhosa?',
			answerOptions: [
				{ answerText: '1.Não tem a ver comigo', pontos: 1 },
				{ answerText: '2.Tem bem pouco a ver comigo', pontos: 2 },
				{ answerText: '3.Faço de maneira mediana', pontos: 3 },
				{ answerText: '4.Tem um pouco a mais a ver comigo', pontos: 4 },
				{ answerText: '5.Tem muito a ver comigo', pontos: 5 },
			],
		},
		{
			questionText: 'Você costuma dedicar um tempo para sair com os amigos? Se divertir?',
			answerOptions: [
				{ answerText: '1.Não tem a ver comigo', pontos: 1 },
				{ answerText: '2.Tem bem pouco a ver comigo', pontos: 2 },
				{ answerText: '3.Faço de maneira mediana', pontos: 3 },
				{ answerText: '4.Tem um pouco a mais a ver comigo', pontos: 4 },
				{ answerText: '5.Tem muito a ver comigo', pontos: 5 },
			],
		},
		{
			questionText: 'Qual o sentimento que você tem ao final do dia. Sente que foi produtivo, que está avançando?',
			answerOptions: [
				{ answerText: '1.Não tem a ver comigo', pontos: 1 },
				{ answerText: '2.Tem bem pouco a ver comigo', pontos: 2 },
				{ answerText: '3.Faço de maneira mediana', pontos: 3 },
				{ answerText: '4.Tem um pouco a mais a ver comigo', pontos: 4 },
				{ answerText: '5.Tem muito a ver comigo', pontos: 5 },
			],
		},
		{
			questionText: 'Ou se sente frustrado, cansado, com a sensação de que não produziu muito?',
			answerOptions: [
				{ answerText: '1.Não tem a ver comigo', pontos: 1 },
				{ answerText: '2.Tem bem pouco a ver comigo', pontos: 2 },
				{ answerText: '3.Faço de maneira mediana', pontos: 3 },
				{ answerText: '4.Tem um pouco a mais a ver comigo', pontos: 4 },
				{ answerText: '5.Tem muito a ver comigo', pontos: 5 },
			],
		},
		{
			questionText: 'Quando inicio uma nova rotina, tenho facilidade em instalá-la em minha vida?',
			answerOptions: [
				{ answerText: '1.Não tem a ver comigo', pontos: 1 },
				{ answerText: '2.Tem bem pouco a ver comigo', pontos: 2 },
				{ answerText: '3.Faço de maneira mediana', pontos: 3 },
				{ answerText: '4.Tem um pouco a mais a ver comigo', pontos: 4 },
				{ answerText: '5.Tem muito a ver comigo', pontos: 5 },
			],
		},
		{
			questionText: 'Você se sente ansioso ao longo dos dias? Costuma se sentir cansado demais e pensando muito no futuro?',
			answerOptions: [
				{ answerText: '1.Não tem a ver comigo', pontos: 1 },
				{ answerText: '2.Tem bem pouco a ver comigo', pontos: 2 },
				{ answerText: '3.Faço de maneira mediana', pontos: 3 },
				{ answerText: '4.Tem um pouco a mais a ver comigo', pontos: 4 },
				{ answerText: '5.Tem muito a ver comigo', pontos: 5 },
			],
		},
		{
			questionText: 'Você consegue se desconectar do trabalho quando está em casa? Se você consegue (isso te muito a ver com você. Agora se você está em casa e fica com a cabeça no trabalho - não tem a ver com você)',
			answerOptions: [
				{ answerText: '1.Não tem a ver comigo', pontos: 1 },
				{ answerText: '2.Tem bem pouco a ver comigo', pontos: 2 },
				{ answerText: '3.Faço de maneira mediana', pontos: 3 },
				{ answerText: '4.Tem um pouco a mais a ver comigo', pontos: 4 },
				{ answerText: '5.Tem muito a ver comigo', pontos: 5 },
			],
		},
		{
			questionText: 'Em momentos que a melhor resposta seria não você consegue dizer? ',
			answerOptions: [
				{ answerText: '1.Não tem a ver comigo', pontos: 1 },
				{ answerText: '2.Tem bem pouco a ver comigo', pontos: 2 },
				{ answerText: '3.Faço de maneira mediana', pontos: 3 },
				{ answerText: '4.Tem um pouco a mais a ver comigo', pontos: 4 },
				{ answerText: '5.Tem muito a ver comigo', pontos: 5 },
			],
		},
		{
			questionText: 'Quando surge algo urgente você consegue se posicionar e dizer não?',
			answerOptions: [
				{ answerText: '1.Não tem a ver comigo', pontos: 1 },
				{ answerText: '2.Tem bem pouco a ver comigo', pontos: 2 },
				{ answerText: '3.Faço de maneira mediana', pontos: 3 },
				{ answerText: '4.Tem um pouco a mais a ver comigo', pontos: 4 },
				{ answerText: '5.Tem muito a ver comigo', pontos: 5 },
			],
		},
		{
			questionText: 'Você sabe todos os dias o que tem que fazer? E o por que faz?',
			answerOptions: [
				{ answerText: '1.Não tem a ver comigo', pontos: 1 },
				{ answerText: '2.Tem bem pouco a ver comigo', pontos: 2 },
				{ answerText: '3.Faço de maneira mediana', pontos: 3 },
				{ answerText: '4.Tem um pouco a mais a ver comigo', pontos: 4 },
				{ answerText: '5.Tem muito a ver comigo', pontos: 5 },
			],
		},
		{
			questionText: 'Você costuma delegar algumas ações? ',
			answerOptions: [
				{ answerText: '1.Não tem a ver comigo', pontos: 1 },
				{ answerText: '2.Tem bem pouco a ver comigo', pontos: 2 },
				{ answerText: '3.Faço de maneira mediana', pontos: 3 },
				{ answerText: '4.Tem um pouco a mais a ver comigo', pontos: 4 },
				{ answerText: '5.Tem muito a ver comigo', pontos: 5 },
			],
		},
		{
			questionText: 'Ou prefere executar sozinho?',
			answerOptions: [
				{ answerText: '1.Não tem a ver comigo', pontos: 1 },
				{ answerText: '2.Tem bem pouco a ver comigo', pontos: 2 },
				{ answerText: '3.Faço de maneira mediana', pontos: 3 },
				{ answerText: '4.Tem um pouco a mais a ver comigo', pontos: 4 },
				{ answerText: '5.Tem muito a ver comigo', pontos: 5 },
			],
		},
		{
			questionText: 'Você procura esclarecer tudo quando delega algo? Procedimentos, métricas, prazos? E anota em sua agenda?',
			answerOptions: [
				{ answerText: '1.Não tem a ver comigo', pontos: 1 },
				{ answerText: '2.Tem bem pouco a ver comigo', pontos: 2 },
				{ answerText: '3.Faço de maneira mediana', pontos: 3 },
				{ answerText: '4.Tem um pouco a mais a ver comigo', pontos: 4 },
				{ answerText: '5.Tem muito a ver comigo', pontos: 5 },
			],
		},
		{
			questionText: 'Você tem o costume deixar as notificações do seu celular visíveis quando chega alguma mensagem? Se fica visível a nota é maior, caso contrário a nota é menor. ',
			answerOptions: [
				{ answerText: '1.Não tem a ver comigo', pontos: 1 },
				{ answerText: '2.Tem bem pouco a ver comigo', pontos: 2 },
				{ answerText: '3.Faço de maneira mediana', pontos: 3 },
				{ answerText: '4.Tem um pouco a mais a ver comigo', pontos: 4 },
				{ answerText: '5.Tem muito a ver comigo', pontos: 5 },
			],
		},
		{
			questionText: 'Você tem controle em relação ao uso do Whatsapp, Instagram e Facebook? ',
			answerOptions: [
				{ answerText: '1.Não tem a ver comigo', pontos: 1 },
				{ answerText: '2.Tem bem pouco a ver comigo', pontos: 2 },
				{ answerText: '3.Faço de maneira mediana', pontos: 3 },
				{ answerText: '4.Tem um pouco a mais a ver comigo', pontos: 4 },
				{ answerText: '5.Tem muito a ver comigo', pontos: 5 },
			],
		},
		{
			questionText: 'Eles (redes sociais) atrapalham a sua produtividade?',
			answerOptions: [
				{ answerText: '1.Não tem a ver comigo', pontos: 1 },
				{ answerText: '2.Tem bem pouco a ver comigo', pontos: 2 },
				{ answerText: '3.Faço de maneira mediana', pontos: 3 },
				{ answerText: '4.Tem um pouco a mais a ver comigo', pontos: 4 },
				{ answerText: '5.Tem muito a ver comigo', pontos: 5 },
			],
		},
		{
			questionText: 'Quando você precisa focar em alguma atividade, você costuma utilizar estratégias para te ajudar?',
			answerOptions: [
				{ answerText: '1.Não tem a ver comigo', pontos: 1 },
				{ answerText: '2.Tem bem pouco a ver comigo', pontos: 2 },
				{ answerText: '3.Faço de maneira mediana', pontos: 3 },
				{ answerText: '4.Tem um pouco a mais a ver comigo', pontos: 4 },
				{ answerText: '5.Tem muito a ver comigo', pontos: 5 },
			],
		},
		{
			questionText: 'No seu trabalho você consegue manter o foco na maior parte do tempo?',
			answerOptions: [
				{ answerText: '1.Não tem a ver comigo', pontos: 1 },
				{ answerText: '2.Tem bem pouco a ver comigo', pontos: 2 },
				{ answerText: '3.Faço de maneira mediana', pontos: 3 },
				{ answerText: '4.Tem um pouco a mais a ver comigo', pontos: 4 },
				{ answerText: '5.Tem muito a ver comigo', pontos: 5 },
			],
		},
		{
			questionText: 'Como você costuma se sentir na segunda feira de manhã? feliz, energizado? ',
			answerOptions: [
				{ answerText: '1.Não tem a ver comigo', pontos: 1 },
				{ answerText: '2.Tem bem pouco a ver comigo', pontos: 2 },
				{ answerText: '3.Faço de maneira mediana', pontos: 3 },
				{ answerText: '4.Tem um pouco a mais a ver comigo', pontos: 4 },
				{ answerText: '5.Tem muito a ver comigo', pontos: 5 },
			],
		},
		{
			questionText: 'No domingo a noite qual a sensação que você tem? Se sente angustiado porque mais uma semana está por iniciar?',
			answerOptions: [
				{ answerText: '1.Não tem a ver comigo', pontos: 1 },
				{ answerText: '2.Tem bem pouco a ver comigo', pontos: 2 },
				{ answerText: '3.Faço de maneira mediana', pontos: 3 },
				{ answerText: '4.Tem um pouco a mais a ver comigo', pontos: 4 },
				{ answerText: '5.Tem muito a ver comigo', pontos: 5 },
			],
		},
		{
			questionText: 'Você sente que o que faz tem um propósito e que você está contribuindo com o mundo?',
			answerOptions: [
				{ answerText: '1.Não tem a ver comigo', pontos: 1 },
				{ answerText: '2.Tem bem pouco a ver comigo', pontos: 2 },
				{ answerText: '3.Faço de maneira mediana', pontos: 3 },
				{ answerText: '4.Tem um pouco a mais a ver comigo', pontos: 4 },
				{ answerText: '5.Tem muito a ver comigo', pontos: 5 },
			],
		},
		{
			questionText: 'Você sente que é entusiasmado? Que cativa as pessoas ao redor?',
			answerOptions: [
				{ answerText: '1.Não tem a ver comigo', pontos: 1 },
				{ answerText: '2.Tem bem pouco a ver comigo', pontos: 2 },
				{ answerText: '3.Faço de maneira mediana', pontos: 3 },
				{ answerText: '4.Tem um pouco a mais a ver comigo', pontos: 4 },
				{ answerText: '5.Tem muito a ver comigo', pontos: 5 },
			],
		},
		{
			questionText: 'Você tem clareza sobre a sua missão de vida?',
			answerOptions: [
				{ answerText: '1.Não tem a ver comigo', pontos: 1 },
				{ answerText: '2.Tem bem pouco a ver comigo', pontos: 2 },
				{ answerText: '3.Faço de maneira mediana', pontos: 3 },
				{ answerText: '4.Tem um pouco a mais a ver comigo', pontos: 4 },
				{ answerText: '5.Tem muito a ver comigo', pontos: 5 },
			],
		},
		{
			questionText: 'Você sente que fazendo o que faz, você será lembrado? Deixará um legado?',
			answerOptions: [
				{ answerText: '1.Não tem a ver comigo', pontos: 1 },
				{ answerText: '2.Tem bem pouco a ver comigo', pontos: 2 },
				{ answerText: '3.Faço de maneira mediana', pontos: 3 },
				{ answerText: '4.Tem um pouco a mais a ver comigo', pontos: 4 },
				{ answerText: '5.Tem muito a ver comigo', pontos: 5 },
			],
		},
		{
			questionText: 'Você tem clareza das atividades diárias que você irá realizar ao longo da semana e que ela irá lhe entregar os melhores resultados?',
			answerOptions: [
				{ answerText: '1.Não tem a ver comigo', pontos: 1 },
				{ answerText: '2.Tem bem pouco a ver comigo', pontos: 2 },
				{ answerText: '3.Faço de maneira mediana', pontos: 3 },
				{ answerText: '4.Tem um pouco a mais a ver comigo', pontos: 4 },
				{ answerText: '5.Tem muito a ver comigo', pontos: 5 },
			],
		},
		{
			questionText: 'Você tem clareza do que precisa e o que não precisa fazer para alcançar o que quer?',
			answerOptions: [
				{ answerText: '1.Não tem a ver comigo', pontos: 1 },
				{ answerText: '2.Tem bem pouco a ver comigo', pontos: 2 },
				{ answerText: '3.Faço de maneira mediana', pontos: 3 },
				{ answerText: '4.Tem um pouco a mais a ver comigo', pontos: 4 },
				{ answerText: '5.Tem muito a ver comigo', pontos: 5 },
			],
		},
		{
			questionText: 'Você tem mapeado o que te faz perder o foco? ',
			answerOptions: [
				{ answerText: '1.Não tem a ver comigo', pontos: 1 },
				{ answerText: '2.Tem bem pouco a ver comigo', pontos: 2 },
				{ answerText: '3.Faço de maneira mediana', pontos: 3 },
				{ answerText: '4.Tem um pouco a mais a ver comigo', pontos: 4 },
				{ answerText: '5.Tem muito a ver comigo', pontos: 5 },
			],
		},
		{
			questionText: 'Você costuma ser pontual?',
			answerOptions: [
				{ answerText: '1.Não tem a ver comigo', pontos: 1 },
				{ answerText: '2.Tem bem pouco a ver comigo', pontos: 2 },
				{ answerText: '3.Faço de maneira mediana', pontos: 3 },
				{ answerText: '4.Tem um pouco a mais a ver comigo', pontos: 4 },
				{ answerText: '5.Tem muito a ver comigo', pontos: 5 },
			],
		},
		{
			questionText: 'Costuma chegar com antecedência nos seus compromissos? ',
			answerOptions: [
				{ answerText: '1.Não tem a ver comigo', pontos: 1 },
				{ answerText: '2.Tem bem pouco a ver comigo', pontos: 2 },
				{ answerText: '3.Faço de maneira mediana', pontos: 3 },
				{ answerText: '4.Tem um pouco a mais a ver comigo', pontos: 4 },
				{ answerText: '5.Tem muito a ver comigo', pontos: 5 },
			],
		},
		{
			questionText: 'Você tem o costume de planejar o tempo que irá gastar para se deslocar para um compromisso? ',
			answerOptions: [
				{ answerText: '1.Não tem a ver comigo', pontos: 1 },
				{ answerText: '2.Tem bem pouco a ver comigo', pontos: 2 },
				{ answerText: '3.Faço de maneira mediana', pontos: 3 },
				{ answerText: '4.Tem um pouco a mais a ver comigo', pontos: 4 },
				{ answerText: '5.Tem muito a ver comigo', pontos: 5 },
			],
		},
	];

export default questions