import React from 'react';
import { useNavigate } from 'react-router-dom'
import styles from '../styles/Final.css'

export default function Enviar() {

    const navigate = useNavigate()

    function handleClick(){
        navigate('/')
    }

    return(
        <div className='box-enviar'>
            <div className='enviar-section'></div>
            <div className='enviar-texto'>
                Muito Obrigado, email enviado com sucesso!
                Clique para retornar a página inicial
                <button onClick={handleClick}>Home</button>                
            </div>

        </div>
    )
}