import React from 'react';
import { Link } from 'react-router-dom'
import styles from '../styles/Home.css'

export default function Home({history}){    

    return(
        <div className='box-home'>
            <div className='home-section'></div>
            <div className='home-texto'>
                <h1>Bem vindo ao Questionário de Produtividade Pessoal!</h1>
                
                <ul>
                    <li>Responda as questões de maneira sincera.</li>
                    <li>Independente de você achar que pode ser melhor ou pior, responda de acordo com quem você vem sendo até hoje. </li>
                    <li>Esse questionário é para você ter uma base de como você vem sendo e agindo diante de diversas situações</li>
                </ul>
                <div className='home-link'>Clique <Link to="/orientacoes">Aqui</Link> para iniciar</div>
            </div>         
        </div>
    )
}