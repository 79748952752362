import React from 'react';
import ReactDOM from 'react-dom';
import { Routes, Route, unstable_HistoryRouter as HistoryRouter } from "react-router-dom"
import { createBrowserHistory } from "history"
import './index.css';
import App from './App';
import Enviar from './components/Enviar';
import Home from './components/Home';
import Final from './components/Final';
import Orientacoes from './components/Orientacoes';

const history = createBrowserHistory({ window })

ReactDOM.render(    
  <React.StrictMode>
    <HistoryRouter history={history}>
      <Routes>

        <Route path="/" element={<Home />} >
          <Route index element={<Home />} />
        </Route>

        <Route path="/enviar" element={<Enviar />} > </Route>
        
        <Route path="/app" element={<App />} > </Route>    
        <Route path="/final" element={<Final/>} > </Route>  
        <Route path="/orientacoes" element={<Orientacoes/>} > </Route>  

      </Routes>
    </HistoryRouter>
  </React.StrictMode>,  
  document.getElementById('root')
);
